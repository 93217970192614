import * as React from "react";
// @ts-ignore
import classes from "./NewsAndMedia.module.css";
import { imageItems } from "./NewsAndMedia.items";
import Modal from "./components/modal/Modal";
import ImageContainer from "./components/imageContainer/ImageContainer";

export const NewsAndMedia = () => {
  const [open, setOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState({ alt: "", filename: "" });

  const handleClick = (props: any) => {
    if (props) {
      setModalData({
        alt: props.alt,
        filename: props.filename,
      });
    }
    setOpen(!open);
  };

  return (
    <div>
      <Modal
        show={open}
        close={handleClick}
        data={modalData}
      />
      <div className={classes.sectionWrapper}>
        <p className={classes.newsHeader}>News & Media</p>
        {
          imageItems.map((image, index) => {
            return (
              <ImageContainer
                key={index}
                {...image}
                handleModal={handleClick}
              />
            );
          })
        }
      </div>
    </div>
  );
};
