import * as React from "react";
import Image from "../../../shared/image/Image";
// @ts-ignore
import classes from "./ImageContainer.module.css";

const ImageContainer = (props: any) => {
  const { alt, filename, header, to, handleModal } = props;

  return (
    <div>
      {header && <h2 className={classes.imageSectionName}>{header}</h2>}
      {
        to ?
          <a
            href={to}
            target="_blank"
            rel="noopener"
          >
            <Image alt={alt} filename={filename} />
          </a> :
          <div onClick={() => handleModal({ alt, filename })}>
            <Image alt={alt} filename={filename} />
          </div>
      }
    </div>
  );
};

export default ImageContainer;
