interface IImageItem {
  alt: string;
  filename: string;
  header?: string;
  to?: string;
}

export const imageItems: IImageItem[] = [
  {
    alt: "Amarillo hops bottle the brightness",
    filename: "australia_talks.png",
    header: "Australia Talks Amarillo®",
    to: "http://bintani.com.au/news-post/amarillo-hops-bottle-the-brightness/",
  },
  {
    alt: "hop report",
    filename: "hop_report.png",
    header: "Amarillo® German Acreage Report",
    to: "pdfs/August-2019-2-english.pdf",
  },
  {
    alt: "visit amarillo",
    filename: "visit_amarillo.png",
    header: "2019 Magazine Ads",
  },
  {
    alt: "summer amarillo",
    filename: "summer_amarillo.png",
  },
  {
    alt: "amarillo pellet",
    filename: "amarillo_pellet.png",
  },
];
