import * as React from "react";
import Image from "../../../shared/image/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
// @ts-ignore
import classes from "./Modal.module.css";

const Modal = (props: any) => {
  const { show, close, data } = props;
  if (!show) {
    return (<></>);
  }
  const { alt, filename } = data;

  return (
    <div className={classes.backdrop} onClick={() => close}>
      <div className={classes.iconContainer}>
        <FontAwesomeIcon icon={faTimes} size="2x" className="icon" onClick={close} />
      </div>
      <div className={classes.innerContainer}>
        <div className={classes.modal}>
          <Image
            alt={alt}
            filename={filename}
          />
        </div>
      </div>
    </div>
  );
};

export default Modal;
